// Init
import React from "react";
import { invoiceDateRange } from "../utils/dateRange";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import axios from "axios";
import { toast } from "react-toastify";

// Component
export const ExportInvoices = ({ input }) => {
  // File Type
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  // Extension
  const fileExtension = ".xlsx";

  // Exporting
  const exportToCSV = (csvData, fileName) => {
    // Sorting
    const myHeader = [
      "invoice_no",
      "seller_name",
      "seller_email",
      "seller_no",
      "transaction_date",
      "currency",
      "amount",
      "status",
      "account_platform",
      "account_title",
      "account_email",
      "dateCreated",
      "transaction_ss",
    ];

    const ws = XLSX.utils.json_to_sheet(csvData, { header: myHeader });

    const wb = { Sheets: { users: ws }, SheetNames: ["users"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  // Filtering invoices by input
  const handleSubmit = async (input) => {
    if (input.status) {
      const invoices = await axios.get("/invoices");
      const status = input.status === "pending" ? true : false;

      // Filtering invoices by role
      const filter = invoices.data.filter((item) => {
        // If user selected account or seller no then ok else setting same as item
        const seller_no = input.seller_no ? input.seller_no : item.seller_no;
        const account = input.account ? input.account : item.account;

        // Filtering invoice by user preference
        if (input.from && input.to) {
          return (
            invoiceDateRange(item.transaction_date, input) &&
            item.seller_no === parseInt(seller_no) &&
            item.account === account &&
            item.active === status
          );
        } else {
          return (
            item.seller_no === parseInt(seller_no) &&
            item.account === account &&
            item.active === status
          );
        }
      });

      // Modifying Invoices to exports
      const modified = filter.map((invoice) => {
        delete invoice._id;
        delete invoice.payment_date;
        delete invoice.expiryDate;
        delete invoice.__v;
        delete invoice.account;
        invoice.status = invoice.active ? "Pending" : "Paid";
        delete invoice.active;
        return invoice;
      });

      // Sorting by given method
      modified.sort((a, b) => a.seller_no - b.seller_no);

      // Calling Export function
      modified.length === 0
        ? toast.error("There are no invoices")
        : exportToCSV(modified, input.status + " invoices - PM Pro");
    } else {
      toast.error("Please select status before generate");
    }
  };

  // Render
  return (
    <button
      className="form-submit-btn"
      style={{ margin: "10px 0" }}
      onClick={() => handleSubmit(input)}
    >
      Generate
    </button>
  );
};
