import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Components
import { ExportCSV } from "../components/ExportExcel.jsx";
import { ExportUsers } from "../components/ExportUsers.jsx";
import { ExportInvoices } from "../components/ExportInvoices.jsx";

// Render
function Excel({ orders, users, accounts, handleReload, role }) {
  // Initializing State
  let [orderInput, setOrderInput] = useState({
    from: "",
    to: "",
    sortMethod: "seller_no",
    excel_status: "",
  });
  let [userInput, setUserInput] = useState("");
  let [invoiceInput, setInvoiceInput] = useState({});

  // Changing the title of page
  useEffect(() => {
    document.title = "Create Excel - PM Pro";
    handleReload("accounts");
  }, []);

  // Order Input
  const handleOrderInput = (e, field) => {
    if (field) {
      setOrderInput((prev) => {
        return {
          ...prev,
          [field]: e,
        };
      });
    } else {
      setOrderInput((prev) => {
        return {
          ...prev,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  // Invoice Input
  const handleInvoiceInput = (e, field) => {
    if (field) {
      setInvoiceInput((prev) => {
        return {
          ...prev,
          [field]: e,
        };
      });
    } else {
      setInvoiceInput((prev) => {
        return {
          ...prev,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  // Render
  return (
    // Main
    <section className="excel form" onSubmit={(e) => e.preventDefault()}>
      {/* Heading */}
      <h2>Create Excel</h2>
      <form className="form-container two-form-col">
        <section>
          <h3>Export Orders</h3>
          <div className="form-input-container">
            <div>
              <label htmlFor="from">From</label>
              <DatePicker
                selected={orderInput.from ?? orderInput.from}
                placeholderText="MM/DD/YYYY"
                id="from"
                onChange={(e) => handleOrderInput(e, "from")}
              />
            </div>
            <div>
              <label htmlFor="to">To</label>
              <DatePicker
                selected={orderInput.to ?? orderInput.to}
                placeholderText="MM/DD/YYYY"
                id="to"
                onChange={(e) => handleOrderInput(e, "to")}
              />
            </div>
          </div>

          {/* User No and sort method and status row */}
          <div className="three-inputs-colspan">
            {/* User No */}
            <input
              type="text"
              name="user_no"
              id="excel-user_no"
              placeholder="User ID (Optional)"
              value={orderInput.user_no}
              onChange={handleOrderInput}
            />

            {/* Sort Method */}
            <div className="sort-radio">
              <span>Sort: </span>
              <div>
                <label className="sort-label" htmlFor="managerSort">
                  Manager
                </label>
                <input
                  type="radio"
                  id="managerSort"
                  name="sortMethod"
                  value="seller_no"
                  checked={orderInput.sortMethod === "seller_no"}
                  onChange={handleOrderInput}
                />
              </div>
              <div>
                <label className="sort-label" htmlFor="userSort">
                  User
                </label>
                <input
                  type="radio"
                  id="userSort"
                  name="sortMethod"
                  value="user_no"
                  checked={orderInput.sortMethod === "user_no"}
                  onChange={handleOrderInput}
                />
              </div>
            </div>

            {/* Status Select */}
            <select name="excel_status" onChange={handleOrderInput}>
              <option value="">Select Order Status</option>
              <option value="ordered">Ordered</option>
              <option value="reviewed">Reviewed</option>
              <option value="delivered">Pending Refund</option>
              <option value="refunded">Refunded</option>
              <option value="review deleted">Review Deleted</option>
              <option value="on hold">On Hold</option>
              <option value="pending">Pending</option>
              <option value="cancelled">Cancelled</option>
              <option value="commissioned">Commissioned</option>
              <option value="completed">Completed</option>
            </select>
          </div>

          {/* Generate btn component */}
          <ExportCSV input={orderInput} orders={orders} />
        </section>

        {/* Second Column */}
        <section>
          {/* User Exports */}
          {role === "admin" && (
            <div className="excel-second-cols">
              <h3>Export Users</h3>
              {/* Select */}
              <select
                name="role"
                style={{ margin: "5px 0" }}
                onChange={(e) => setUserInput(e.target.value)}
              >
                <option value="">Select User Role</option>
                <option value="user">Users</option>
                <option value="manager">Managers</option>
                <option value="admin">Admins</option>
              </select>

              {/* Generate btn component */}
              <ExportUsers input={userInput} users={users} />
            </div>
          )}

          {/* Invoices Exports */}
          <div className="excel-second-cols">
            <h3 style={{ marginTop: "20px" }}>Export Invoices</h3>
            {/* Select */}
            <div className="form-input-container">
              <div>
                <label htmlFor="from">From</label>
                <DatePicker
                  selected={invoiceInput.from ?? invoiceInput.from}
                  placeholderText="MM/DD/YYYY"
                  id="from"
                  onChange={(e) => handleInvoiceInput(e, "from")}
                />
              </div>
              <div>
                <label htmlFor="to">To</label>
                <DatePicker
                  selected={invoiceInput.to ?? invoiceInput.to}
                  placeholderText="MM/DD/YYYY"
                  id="to"
                  onChange={(e) => handleInvoiceInput(e, "to")}
                />
              </div>
            </div>
            <div className="three-inputs-colspan">
              <input
                type="text"
                name="seller_no"
                placeholder="Seller ID (Optional)"
                value={invoiceInput.seller_no}
                onChange={handleInvoiceInput}
              />
              <select
                name="account"
                onChange={handleInvoiceInput}
                style={{ margin: "5px 0" }}
              >
                <option value="">Select Account</option>
                {accounts.map((account) => {
                  return (
                    <option
                      key={account._id}
                      value={account._id}
                    >{`${account.platform} - ${account.title}`}</option>
                  );
                })}
              </select>
              <select
                name="status"
                style={{ margin: "5px 0" }}
                onChange={handleInvoiceInput}
              >
                <option value="">Select Status</option>
                <option value="pending">Pending</option>
                <option value="paid">Paid</option>
              </select>
            </div>

            {/* Generate btn component */}
            <ExportInvoices input={invoiceInput} />
          </div>
        </section>
      </form>
    </section>
  );
}

// Export
export default Excel;
