// Init
import { analytics } from "../firebase";
import Compress from "browser-image-compression";
import axios from "axios";
import { toast } from "react-toastify";

// Handle Image
const handleInput = (e, setProduct, setProgress) => {
  // Checking if user uploaded an image
  if (e.target.name === "amazon_pic" || e.target.name === "pic") {
    const file = e.target.files[0]; // Getting Image

    // Compression config
    const options = {
      maxSizeMB: 1, // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: 750, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
      useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
    };

    // Compressing
    Compress(file, options)
      .then((compressedBlob) => {
        // Adding metadeta
        compressedBlob.lastModifiedDate = new Date();

        // Convert the blob to file
        const image = new File([compressedBlob], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });

        setProduct((prev) => {
          return {
            ...prev,
            [e.target.name]: image,
          };
        });
      })
      .catch((e) => {
        // Show the user a toast message or notification that something went wrong while compressing file
      });
  } else {
    // Input
    setProduct((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }
};

// Handle Bulk Upload
const handleBulkUpload = (e) => {
  let formData = new FormData();
  formData.append("products", e.target.files[0]);

  // Creating Product
  axios
    .post("/products/bulk", formData)
    .then(() => {
      // Resetting States
      toast.success("Products added successfully.");

      // Logging analytics
      analytics.logEvent("Bulk Products Added");
    })
    .catch((err) => {
      // If session expired then redirect lo login page
      if (err.response.status === 401) {
        window.location = "/login";
        toast.error(err.response.data);
      } else if (err.response.status === 400 || err.response.status === 403) {
        toast.error(err.response.data);
      } else {
        console.log({ ...err });
        toast.error("Something went wrong.");
      }
    });
};

// Handle Submit
const handleSubmit = (e, product, setProduct, setLoading, setProgress) => {
  e.preventDefault();

  // Checking if sale limit lesser than overall limit
  if (parseInt(product.sale_limit) > parseInt(product.overall_sale_limit)) {
    toast.error("Sale limit must be lesser than overall limit...");
  } else {
    // If Ok
    setLoading(true);

    // Making form data
    const formData = new FormData();
    for (const property in product) {
      formData.append(property, product[property]);
    }
    formData.append("active", parseInt(product.sale_limit) > 0 ? true : false);

    // Creating Product
    axios
      .post("/products", formData)
      .then(() => {
        // Resetting States
        setLoading(false);
        toast.success("Product added successfully.");
        setProduct((prev) => {
          return {
            ...prev,
            keyword: "",
            brand_name: "",
            amz_seller: "",
            chinese_seller: "",
            amazon_pic: "",
            amz_link: "",
            pic: "",
            commission: "",
            sale_limit: "",
            overall_sale_limit: "",
          };
        });
        setProgress({ amazon_pic: 0, pic: 0 });

        // Resetting File input value
        document.getElementById("amazonPic").value = "";
        document.getElementById("productPic").value = "";

        // Logging analytics
        analytics.logEvent("Products Added");
      })
      .catch((err) => {
        // If session expired then redirect lo login page
        if (err.response.status === 401) {
          window.location = "/login";
          toast.error(err.response.data);
        } else if (err.response.status === 400 || err.response.status === 403) {
          toast.error(err.response.data);
          setLoading(false);
        } else {
          console.log({ ...err });
          toast.error("Something went wrong.");
          setLoading(false);
        }
      });
  }
};

// Export
export { handleInput, handleBulkUpload, handleSubmit };
