// Init
import React, { useEffect, useState } from "react";
import {Switch, Route, useHistory, useLocation, Redirect} from "react-router-dom";
import axios from "axios";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Importing login page
import Login from "./containers/Login.jsx";

// Importing Signup page
import Signup from "./containers/Signup.jsx";

// Importing Route
import Routes from "./routes";

// Auth
import checkAuth from "./api/auth/checkAuth";

// CSS
import "./css/App.css";

// Component
function App() {
  // Initializing State
  let [loggedIn, setLoggedIn] = useState(false);
  let [user, setUser] = useState({});
  let { pathname } = useLocation();
  let history = useHistory(); // Using history to push not authenticated routes to login page

  // Axios Setting if production build
  if (window.location.hostname !== "localhost") {
    axios.defaults.baseURL = "https://api.pmpro.com.pk/api/";
    axios.defaults.withCredentials = true;
  } else {
    axios.defaults.baseURL = "http://localhost:5000/api/";
    axios.defaults.withCredentials = true;
  }

  // Checking is user Authenticated or not
  useEffect(() => {
    checkAuth(setUser, setLoggedIn, pathname, history);
  }, []);

  // Render
  return (
    <>
      {/* tostify Container for Notification */}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        transition={Flip}
      />

      {/* Routes */}
      <Switch>
        <Redirect exact path="/" to="/login" />
        <Route
          exact
          path="/login"
          render={() => (!loggedIn ? <Login /> : history.push("/dashboard"))}
        />
        <Route
          exact
          path="/signup"
          render={() => (!loggedIn ? <Signup /> : history.push("/dashboard"))}
        />
        <Route
          exact
          path="/*"
          render={() =>
            loggedIn ? <Routes role={user.role} user={user} /> : null
          }
        />
      </Switch>
    </>
  );
}

// Export
export default App;
