// Generate whatsapp chat link
const generateWALink = (phone) => {
  if (!phone) {
    return "N/A";
  }

  if (!phone.includes("+")) {
    phone = phone[0] === "0" ? phone.replace("0", "+92") : `+92${phone}`;
  }

  return (
    <a href={`https://wa.me/${phone}`} target="_blank" title={phone}>
      <i
        className="fab fa-whatsapp"
        style={{ color: "#25D366", transform: "scale(2)" }}
      ></i>
    </a>
  );
};

export default generateWALink;
