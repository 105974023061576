// Init
import React, { useState, useEffect } from "react";

// utils
import handleSubmit from "../utils/password";

// Component
function Password() {
  // Initializing States
  let [input, setInput] = useState({});
  let [loading, setLoading] = useState(false);

  // Changing the title of page
  useEffect(() => {
    document.title = "Password - PM Pro";
  }, []);

  // Handle Input
  const handleInput = (e) => {
    setInput((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  // Render
  return (
    <section className="password form">
      <h2>Change Password</h2>
      <form onSubmit={(e) => handleSubmit(e, input, setInput, setLoading)}>
        <h3>Update Password</h3>
        <section className="form-container">
          <input
            type="password"
            name="oldPassword"
            placeholder="Old Password*"
            value={input.oldPassword}
            onChange={handleInput}
            required
          />
          <input
            type="password"
            name="password"
            placeholder="New Password*"
            value={input.password}
            onChange={handleInput}
            required
          />
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password*"
            value={input.confirmPassword}
            onChange={handleInput}
            required
          />
        </section>

        {/* Submit Button */}
        <button type="submit" className="form-submit-btn" disabled={loading}>
          {loading && <i className="fa fa-refresh fa-spin" />}
          &nbsp; Add Now
        </button>
      </form>
    </section>
  );
}

// Export
export default Password;
