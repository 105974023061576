// Init
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

// Pic Model
import PicModel from "../components/PicModel.jsx";

// utils
import { handleInput, handleSubmit } from "../utils/invoice";

// View Component
function ViewInvoice({ invoice, role }) {
  return (
    // Details Section
    <ul>
      <li>
        <div>
          <h5>Seller Name : </h5>
          <span>{invoice.seller_name}</span>
        </div>
        <div>
          <h5>Seller Email : </h5>
          <span>{invoice.seller_email}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Invoice ID : </h5>
          <span>{invoice.invoice_no}</span>
        </div>
        <div>
          <h5>Seller ID : </h5>
          <span>{invoice.seller_no}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Currency : </h5>
          <span>{invoice.currency}</span>
        </div>
        <div>
          <h5>Amount : </h5>
          <span>{invoice.amount}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Transaction Date : </h5>
          <span>{invoice.transaction_date}</span>
        </div>
        <div>
          <h5>Status : </h5>
          <span>{invoice.active ? "Pending" : "Paid"}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Platform : </h5>
          <span>{invoice.account_platform}</span>
        </div>
        <div>
          <h5>Ac Title : </h5>
          <span>{invoice.account_title}</span>
        </div>
      </li>
      <li>
        {role === "manager" && invoice.account_no ? (
          <div>
            <h5>Ac Number : </h5>
            <span>{invoice.account_no}</span>
          </div>
        ) : (
          <div>
            <h5>Ac Email : </h5>
            <span>{invoice.account_email}</span>
          </div>
        )}
        <div>
          <h5>Date Created : </h5>
          <span>{invoice.dateCreated}</span>
        </div>
      </li>
    </ul>
  );
}

// Edit Component
function EditInvoice({
  invoice,
  accounts,
  handleReload,
  setInvoice,
  setEditing,
}) {
  let [loading, setLoading] = useState(false);

  // Getting Latest Accounts
  useEffect(() => {
    handleReload("accounts");
  }, []);

  // Render
  return (
    // Form
    <form
      onSubmit={(e) =>
        handleSubmit(e, invoice, setInvoice, setEditing, setLoading)
      }
    >
      <ul className="view-update-form">
        <h3>Update invoice</h3>
        <li className="form-input-container">
          <input
            type="date"
            name="transaction_date"
            value={invoice.transaction_date}
            onChange={(e) => handleInput(e, setInvoice)}
            required
          />
          <input
            type="text"
            name="currency"
            placeholder="Currency"
            value={invoice.currency}
            onChange={(e) => handleInput(e, setInvoice)}
            required
          />
        </li>
        <li className="form-input-container">
          <input
            type="number"
            step="any"
            name="amount"
            placeholder="Amount"
            value={invoice.amount}
            onChange={(e) => handleInput(e, setInvoice)}
            required
          />
          <select
            required
            name="account"
            onChange={(e) => handleInput(e, setInvoice)}
          >
            {accounts.map((account) => {
              return (
                <option
                  key={account._id}
                  value={account._id}
                  selected={account._id === invoice.account}
                >{`${account.platform} - ${account.title}`}</option>
              );
            })}
          </select>
        </li>

        {/* Update btn */}
        <button className="form-submit-btn" disabled={loading}>
          {loading && <i className="fa fa-refresh fa-spin" />}
          &nbsp; Update Now
        </button>
      </ul>
    </form>
  );
}

// Main Component
function Invoice({ invoices, accounts, handleReload, role }) {
  // Initializing States
  let [invoice, setInvoice] = useState({});
  let [editing, setEditing] = useState(false);
  let { invoiceNo } = useParams();
  let [picModel, setPicModel] = useState({ display: false, src: "" });
  let history = useHistory();

  // Filtering Invoice and changing page title
  useEffect(() => {
    // Changing the title of page
    document.title = "Invoice Detail - PM Pro";

    if (history.action === "POP") {
      // Getting the Invoice
      handleReload("invoices");
    }
  }, [invoiceNo]);

  useEffect(() => {
    // Filtering Invoice
    setInvoice({
      ...invoices.filter((ary) => ary.invoice_no == invoiceNo)[0],
    });
  }, [invoices]);

  // Render
  return (
    <section className="view">
      {/* Image Model */}
      {picModel.display && <PicModel model={picModel} setModel={setPicModel} />}

      {/* Head */}
      <div className="view-heading">
        <span>OVERVIEW</span>
        <h3>Invoice Details</h3>
      </div>

      {/* Body */}
      <div className="view-body">
        {/* Picture Section */}
        <div className="view-pics invoice-pic">
          {/* invoice Pic */}
          <div className="view-pic" style={{ border: "none" }}>
            <h3>Picture</h3>
            {invoice.transaction_ss && (
              <img
                src={invoice.transaction_ss}
                alt="Invoice Pic"
                onClick={() =>
                  setPicModel({
                    display: true,
                    src: invoice.transaction_ss,
                  })
                }
              />
            )}

            {/* File Input */}
            {invoice.active ? (
              <label htmlFor="invoice-pic" className="file-upload-btn">
                <i className="fas fa-camera"></i>
                &nbsp; Change Pic
                <input
                  type="file"
                  id="invoice-pic"
                  name="transaction_ss"
                  onChange={(e) => handleInput(e, setInvoice, invoice)}
                  hidden
                />
              </label>
            ) : null}
          </div>
        </div>

        {/* View Details Section */}

        <div className="view-details">
          {/* Head */}
          <div className="view-details-head">
            <h4>Details</h4>
            {invoice.active ? (
              <button
                className="green-btn"
                onClick={() => setEditing(!editing)}
              >
                <i className="fas fa-pen"></i>&nbsp;
                {editing ? "Cancel" : "Edit"}
              </button>
            ) : null}
          </div>

          {/* Body */}
          {editing ? (
            <EditInvoice
              invoice={invoice}
              accounts={accounts}
              handleReload={handleReload}
              setInvoice={setInvoice}
              setEditing={setEditing}
            />
          ) : (
            <ViewInvoice invoice={invoice} role={role} />
          )}
        </div>
      </div>
    </section>
  );
}

// Export
export default Invoice;
