// Init
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Controller
import { handleInput, handleSubmit } from "../api/auth/login";

// Logo
import logo from "../assets/logo.png";

// Component
function Login() {
  // Initializing States
  let [login, setLogin] = useState({ email: "", password: "" });
  let [loading, setLoading] = useState(false);

  // Changing the title of page
  useEffect(() => {
    document.title = "Login - PM Pro";
  }, []);

  // Render
  return (
    <section className="login-container">
      <div className="login">
        {/* Logo */}
        <div className="login-logo">
          <img src={logo} alt="Logo" />
        </div>

        {/* Login Form */}
        <form onSubmit={(e) => handleSubmit(e, setLoading, login)}>
          {/* Inputs */}
          <input
            type="email"
            name="email"
            autoComplete="email"
            placeholder="Email"
            value={login.email}
            onChange={(e) => handleInput(e, setLogin)}
            required
            autoFocus
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={login.password}
            onChange={(e) => handleInput(e, setLogin)}
            required
          />
          <button type="submit" disabled={loading}>
            {loading && <i className="fa fa-refresh fa-spin" />}
            &nbsp;LOGIN
          </button>
          <p className="signup-btn">
            Don't have an account?&nbsp;
            <Link className="link" to="/signup">
              Sign up here
            </Link>
          </p>
        </form>
      </div>
    </section>
  );
}

// Export
export default Login;
