// Init
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

// utils
import { handleStatus, handleDelete } from "../utils/users";
import Search from "../components/Search";
import generateWALink from "../utils/generateWALink";

// Components
import Pagination from "../components/Pagination.jsx";

// Component
function Users({ status, handleReload, users, setUsers }) {
  // Initializing state to get data
  let [data, setData] = useState([]);
  let [loading, setLoading] = useState();
  let [statusLoading, setStatusLoading] = useState();

  // Initializing History
  let history = useHistory();

  // Changing the title of page
  useEffect(() => {
    if (history.action !== "POP") {
      handleReload("users");
    }

    document.title = "Users - PM Pro";
  }, []);

  // Rendering
  return (
    // Main
    <section className="main">
      {/* Main Heading */}
      <h2>Users</h2>

      {/* Main container */}
      <div className="main-container">
        {/* Container Head */}
        <div className="main-container-head">
          <div>
            <h3 style={{ textTransform: "capitalize" }}>{status} Users</h3>
            &nbsp;-&nbsp;
            <span>(Total {users.length})</span>
          </div>

          {/* Search */}
          <Search
            collection="users"
            field="name"
            status={status}
            handleReload={handleReload}
            setData={setUsers}
          />
        </div>

        {/* Table */}
        <div className="table-container">
          <table className="main-table">
            {/* Table Head */}
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>CNIC</th>
                <th>Email</th>
                <th>Whatsapp</th>
                <th>Gender</th>
                <th>City</th>
                <th>Country</th>
                <th>Role</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {/* Mapping data */}
              {data.map((user, index) => {
                return (
                  <tr key={index} className="user-row">
                    <td>{user.uid}</td>
                    <td>
                      <Link className="link" to={`/profile/${user.uid}`}>
                        {user.name}
                      </Link>
                    </td>
                    <td>{user.cnic}</td>
                    <td>{user.email}</td>
                    <td>{generateWALink(user.number)}</td>
                    <td>{user.gender}</td>
                    <td>{user.city}</td>
                    <td>{user.country}</td>
                    <td>{user.role}</td>

                    {/* Enable/Disable Buttons */}
                    <td>
                      {status === "enabled" ? (
                        <button
                          className="green-btn"
                          id="disabled"
                          disabled={statusLoading === index}
                          onClick={(e) =>
                            handleStatus(
                              user._id,
                              e.target.id,
                              handleReload,
                              index,
                              setStatusLoading,
                            )
                          }
                        >
                          {statusLoading === index && (
                            <i className="fa fa-refresh fa-spin" />
                          )}
                          &nbsp; Disable
                        </button>
                      ) : (
                        <button
                          className="enabled-btn"
                          id="enabled"
                          disabled={statusLoading === index}
                          onClick={(e) =>
                            handleStatus(
                              user._id,
                              e.target.id,
                              handleReload,
                              index,
                              setStatusLoading,
                            )
                          }
                        >
                          {statusLoading === index && (
                            <i className="fa fa-refresh fa-spin" />
                          )}
                          &nbsp; Enable
                        </button>
                      )}
                    </td>

                    {/* Delete Button */}
                    <td style={{ textAlign: "left" }}>
                      <button
                        className="del-btn"
                        disabled={loading == index}
                        onClick={() =>
                          handleDelete(
                            user.email,
                            user._id,
                            handleReload,
                            index,
                            setLoading,
                          )
                        }
                      >
                        {loading === index && (
                          <i className="fa fa-refresh fa-spin" />
                        )}
                        &nbsp; Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <Pagination data={users} setData={setData} />
      </div>
    </section>
  );
}

// Export
export default Users;
