// Init
import axios from "axios";
import { toast } from "react-toastify";

// Handle Status
const handleStatus = (id, status, handleReload, index, setStatusLoading) => {
  setStatusLoading(index);

  // API Calling
  axios
    .patch("/users/status", { _id: id, status: status })
    .then(() => {
      toast.success("User status updated.");
      setStatusLoading();
      handleReload("users");
    })
    .catch((err) => {
      // If session expired then redirect lo login page
      if (err.response.status === 401) {
        toast.error(err.response.data);
        window.location = "/login";
      } else {
        toast.error("Something went wrong.");
      }
      setStatusLoading();
      console.log({ ...err });
    });
};

// Handle Delete
const handleDelete = (email, id, handleReload, index, setLoading) => {
  // Checking if user try to delete owners
  if (email === "yousufkalim@outlook.com") {
    toast.error("You cannot delete the owner/developer...");
  } else {
    // If OK
    const confirm = prompt(`Please type ${email} to confirm.`);
    setLoading(index);

    // If admin confirm the email in prompt
    if (confirm === email) {
      axios
        .delete("/users", { data: { _id: id } })
        .then(() => {
          toast.success("User deleted successfully.");
          handleReload("users");
          setLoading();
        })
        .catch((err) => {
          toast.error("Something went wrong.");
          console.log(err);
          setLoading();
        });
    } else if (confirm && confirm !== email) {
      // If wrong email
      toast.error("You have entered a wrong email address!");
      setLoading();
    } else {
      setLoading();
    }
  }
};

// Export
export { handleDelete, handleStatus };
