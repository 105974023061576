// Init
import React, { useEffect, useState } from "react";

// Controller
import {
  handleInput,
  handleProfilePic,
  handleSubmit,
  performanceCalc,
} from "../utils/profile";

// Image
import male from "../assets/male.png";

// Component
function Profile({ handleReload, orders, user, restricted, role }) {
  // initializing States
  let [profile, setProfile] = useState({});
  let [loading, setLoading] = useState(false);
  let [performance, setPerformance] = useState(0);

  useEffect(() => {
    // Changing the title of page
    document.title = "Profile - PM Pro";
    setProfile({ ...user });
    performanceCalc(orders, setPerformance);
  }, [user, orders]);

  // Render
  return (
    // Main
    <section className="profile">
      {/* Profile Head */}
      <div className="profile-heading">
        <span>OVERVIEW</span>
        <h3>{profile.name}</h3>
      </div>

      {/* Profile Body */}
      <div className="profile-body">
        {/* Profile Overview */}
        <div className="profile-body-overview">
          <div className="profile-overview">
            <div className="profile-pic-container">
              {restricted ? null : (
                <>
                  <label htmlFor="profile_pic">
                    <i className="fas fa-camera"></i>
                  </label>
                  <input
                    type="file"
                    name="profile_pic"
                    id="profile_pic"
                    onChange={(e) => handleProfilePic(e, user, handleReload)}
                    hidden
                    disabled={restricted}
                  />
                </>
              )}
              <img
                src={user.profile_pic ? user.profile_pic : male}
                alt="Profile Picture"
              />
            </div>
            <h3>{profile.name}</h3>
            <span>{profile.role}</span>
          </div>

          <div className="profile-progress">
            <div>
              <span>Performance</span>
              <span>{performance ? performance.toFixed(1) : 0}%</span>
            </div>
            <progress
              value={performance ? performance.toFixed(1) : 0}
              max="100"
            ></progress>
          </div>
        </div>

        {/* Profile Details  */}
        <div className="profile-body-details">
          <h4>Account Details</h4>
          <form
            className="form-container profile-form"
            onSubmit={(e) =>
              handleSubmit(
                e,
                profile,
                setProfile,
                handleReload,
                setLoading,
                restricted,
              )
            }
          >
            <section>
              <div className="form-input-container form-row">
                <div>
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={profile.name}
                    disabled={role !== "admin"}
                    onChange={(e) => handleInput(e, setProfile)}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="gender">Gender</label>
                  <input
                    type="text"
                    id="gender"
                    name="gender"
                    maxLength="6"
                    value={profile.gender}
                    disabled={role !== "admin"}
                    onChange={(e) => handleInput(e, setProfile)}
                    required
                  />
                </div>
              </div>
              <div className="form-input-container form-row">
                <div>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={profile.email}
                    disabled={role !== "admin"}
                    onChange={(e) => handleInput(e, setProfile)}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="cnic">CNIC</label>
                  <input
                    type="number"
                    id="cnic"
                    name="cnic"
                    value={profile.cnic}
                    onChange={(e) => handleInput(e, setProfile)}
                    required
                    disabled={role !== "admin"}
                  />
                </div>
              </div>

              {/* Editable */}
              {/* we have give access only admins to reset user's password, and not own */}
              <div
                className={`${
                  role === "admin" && "form-input-container"
                } form-row`}
              >
                {/* Only show password input if admin viewing user's profile */}
                {role === "admin" && (
                  <div>
                    <label htmlFor="newPassword">Password</label>
                    <input
                      type="password"
                      id="newPassword"
                      name="newPassword"
                      value={profile.newPassword}
                      onChange={(e) => handleInput(e, setProfile)}
                    />
                  </div>
                )}
                <div>
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={profile.address}
                    onChange={(e) => handleInput(e, setProfile)}
                    disabled={restricted}
                  />
                </div>
              </div>

              <div className="three-inputs-colspan form-row">
                <div>
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    value={profile.city}
                    onChange={(e) => handleInput(e, setProfile)}
                    disabled={restricted}
                  />
                </div>

                <div>
                  <label htmlFor="country">Country</label>
                  <input
                    type="text"
                    name="country"
                    id="country"
                    value={profile.country}
                    onChange={(e) => handleInput(e, setProfile)}
                    disabled={restricted}
                  />
                </div>

                <div>
                  <label htmlFor="number">Phone</label>
                  <input
                    type="tel"
                    name="number"
                    id="number"
                    value={profile.number}
                    onChange={(e) => handleInput(e, setProfile)}
                    disabled={restricted}
                    required
                  />
                </div>
              </div>

              <div className="two-inputs-colspan form-row">
                <div>
                  <label htmlFor="bankName">Bank Name</label>
                  <input
                    type="text"
                    name="bankName"
                    id="bankName"
                    value={profile.bankName}
                    onChange={(e) => handleInput(e, setProfile)}
                    disabled={restricted}
                  />
                </div>

                <div>
                  <label htmlFor="bankAccount">Account</label>
                  <input
                    type="text"
                    name="bankAccount"
                    id="bankAccount"
                    value={profile.bankAccount}
                    onChange={(e) => handleInput(e, setProfile)}
                    disabled={restricted}
                  />
                </div>
              </div>

              <div className="form-input-container form-row">
                <div>
                  <label htmlFor="referral_name">Referral Name</label>
                  <input
                    type="text"
                    name="referral_name"
                    id="referral_name"
                    value={profile.referral_name}
                    onChange={(e) => handleInput(e, setProfile)}
                    disabled={restricted}
                  />
                </div>

                <div>
                  <label htmlFor="referral_number">Referral Number</label>
                  <input
                    type="text"
                    name="referral_number"
                    id="referral_number"
                    value={profile.referral_number}
                    onChange={(e) => handleInput(e, setProfile)}
                    disabled={restricted}
                  />
                </div>
              </div>

              {/* Submit Btn */}
              {!restricted && (
                <button type="submit" className="green-btn" disabled={loading}>
                  {loading && <i className="fa fa-refresh fa-spin" />}
                  &nbsp; Update Account
                </button>
              )}
            </section>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Profile;
