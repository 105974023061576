// Init
import React, { useState, useEffect } from "react";

// utils
import {
  handleInput,
  handleSubmit,
  handleUpdate,
  handleResolve,
  handleRead,
} from "../utils/support";

// Pagination
import Pagination from "../components/Pagination.jsx";

// Read Component
function Read({ active, chat, user, setReading, handleReload }) {
  // Initializing States
  let [replying, setReplying] = useState(false);
  let [reply, setReply] = useState("");
  let [loading, setLoading] = useState(null);
  let [temp, setTemp] = useState([]);

  // Render
  return (
    // Read section
    <section className="support-read">
      <div className="support-read-container">
        {/* Support Head */}
        <div className="support-head">
          <h2>{chat.subject}</h2>
          <div className="support-head-btn">
            <button
              className="del-btn"
              style={{ marginRight: "10px" }}
              onClick={() => setReading(false)}
            >
              <i className="fas fa-arrow-left"></i>
              &nbsp;Back
            </button>
            {active && (
              <button
                disabled={loading !== null}
                className="green-btn"
                onClick={() =>
                  handleResolve(chat._id, setLoading, handleReload, setReading)
                }
              >
                {loading !== null ? (
                  <i className="fa fa-refresh fa-spin" />
                ) : (
                  <i className="fas fa-check"></i>
                )}
                &nbsp;Issue Resolved
              </button>
            )}
          </div>
        </div>

        {/* Support body */}
        <ul className="support-body">
          {/* Mapping Messages that we fetched from db */}
          {chat.messages.map((msg, index) => {
            return (
              <li key={index}>
                <p>
                  By <span>{msg.author}</span> on {msg.dateAdded}
                </p>
                <p>{msg.message}</p>
              </li>
            );
          })}

          {/* Mapping replies that user made without fetching it from db */}
          {temp.map((msg, index) => {
            return (
              <li key={index}>
                <p>
                  By <span>{msg.author}</span> on {msg.dateAdded}
                </p>
                <p>{msg.message}</p>
              </li>
            );
          })}

          {/* Reply Text Area */}
          {replying ? (
            <>
              <textarea
                name="message"
                cols="30"
                rows="4"
                autoFocus
                placeholder="Type your reply here..."
                onChange={(e) => setReply(e.target.value)}
              ></textarea>

              {/* Reply and Send buttons */}
              <div className="reply-btn-container">
                <button
                  className="green-btn reply-btn"
                  onClick={(e) =>
                    handleUpdate(
                      chat._id,
                      reply,
                      user,
                      setReplying,
                      setTemp,
                      handleReload,
                    )
                  }
                >
                  <i className="fas fa-reply"></i> &nbsp; Send
                </button>
              </div>
            </>
          ) : (
            <div className="reply-btn-container">
              {active && (
                <button
                  className="green-btn reply-btn"
                  onClick={() => setReplying(true)}
                >
                  <i className="fas fa-reply"></i> &nbsp; Reply
                </button>
              )}
            </div>
          )}
        </ul>
      </div>
    </section>
  );
}

// Messages Component
function Messages({
  role,
  active,
  setReading,
  user,
  messages,
  handleReload,
  setChat,
}) {
  // initializing states
  let [creating, setCreating] = useState(false);
  let [message, setMessage] = useState({});
  let [loading, setLoading] = useState();
  let [data, setData] = useState([]);

  // Render
  return (
    // Main
    <section className="main">
      {/* Main Heading */}
      <h2>Support</h2>

      {/* Main Container */}
      <div className="main-container">
        {/* Main Container Head */}
        <div className="main-container-head">
          <div>
            <h3>{active ? "Active" : "Resolved"} issues</h3>
            &nbsp;-&nbsp;
            <span>(Total {messages.length})</span>
          </div>
          {active && role === "user" ? (
            <button
              className="green-btn issue-btn"
              style={{ marginRight: "55px" }}
              onClick={() => setCreating(!creating)}
            >
              {creating ? "Cancel" : "Create New Issue"}
            </button>
          ) : null}
        </div>

        {/* Send message form */}
        {creating ? (
          <form
            className="form-container"
            style={{
              padding: "20px 10px",
              borderBottom: "1px solid #e1e5eb",
            }}
            onSubmit={(e) =>
              handleSubmit(e, message, setMessage, setCreating, handleReload)
            }
          >
            <section>
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                value={message.subject}
                onChange={(e) => handleInput(e, setMessage)}
                required
              />
              <input
                type="text"
                name="seller_no"
                placeholder="Seller ID"
                value={message.seller_no}
                onChange={(e) => handleInput(e, setMessage)}
                required
              />
              <textarea
                name="message"
                cols="30"
                rows="7"
                placeholder="Your message here..."
                value={message.message}
                onChange={(e) => handleInput(e, setMessage)}
                required
              ></textarea>
              <button
                type="submit"
                className="form-submit-btn"
                onChange={(e) => handleInput(e, setMessage)}
              >
                Send
              </button>
            </section>
          </form>
        ) : null}

        {/* Table */}
        <div className="table-container">
          <table className="main-table">
            {/* Table Head */}
            <thead>
              <tr>
                <th>ID</th>
                <th>Seller ID</th>
                <th>Subject</th>
                <th>Message</th>
                <th>Creation Date</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr
                    className="support-row"
                    key={index}
                    style={
                      item.unread && item.sender !== user.uid
                        ? {
                            backgroundColor: "rgb(234 234 234)",
                          }
                        : null
                    }
                  >
                    <td>{item.user_no}</td>
                    <td>{item.seller_no}</td>
                    <td>{item.subject}</td>
                    <td>{item.message.substring(0, 60) + "..."}</td>
                    <td>{item.dateCreated}</td>

                    {/* Read Button */}
                    <td style={{ textAlign: "right" }}>
                      <button
                        className="green-btn"
                        onClick={() => {
                          setReading(true);
                          setChat(item);
                          handleRead(item, user, role, handleReload);
                        }}
                      >
                        <i className="fas fa-eye"></i>
                        &nbsp;Read
                      </button>
                    </td>

                    {/* Resolve Button */}
                    <td>
                      {active ? (
                        <button
                          className="green-btn"
                          disabled={loading === index}
                          onClick={() =>
                            handleResolve(
                              item._id,
                              setLoading,
                              handleReload,
                              setReading,
                              index,
                            )
                          }
                        >
                          {loading === index ? (
                            <i className="fa fa-refresh fa-spin" />
                          ) : (
                            <i className="fas fa-check"></i>
                          )}
                          &nbsp;Issue Resolved
                        </button>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <Pagination data={messages} setData={setData} />
      </div>
    </section>
  );
}

// Main Component
function Support({ role, active, user, messages, handleReload }) {
  // Initializing States
  let [reading, setReading] = useState(false);
  let [chat, setChat] = useState({});

  // Refresh messages on load
  useEffect(() => {
    handleReload("messages");
    // Changing the title of page
    document.title = "Support - PM Pro";
  }, []);

  // Show table when component changes
  useEffect(() => {
    setReading(false);
  }, [active]);

  // Render
  return reading ? (
    <Read
      active={active}
      chat={chat}
      user={user}
      setReading={setReading}
      handleReload={handleReload}
    />
  ) : (
    <Messages
      role={role}
      active={active}
      setReading={setReading}
      user={user}
      handleReload={handleReload}
      messages={messages}
      setChat={setChat}
    />
  );
}

// Export
export default Support;
