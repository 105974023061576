import React, { useState, useEffect } from "react";

function MarketDropdown({ product, setProduct }) {
  // Initializing States
  let [market, setMarket] = useState("US");
  let [market_cat, setMarket_cat] = useState("");

  // Setting market to states we got from product
  useEffect(() => {
    setMarket(product.market.split(" - ")[0]);
    setMarket_cat(
      product.market.split(" - ")[1]
        ? " - " + product.market.split(" - ")[1]
        : "",
    );
  }, []);

  // Handle Change
  const handleChange = (e) => {
    if (e.target.name === "market_cat") {
      setMarket_cat(e.target.value);
      setProduct((prev) => {
        return {
          ...prev,
          market: market + e.target.value,
        };
      });
    } else if (e.target.name === "market_type") {
      setProduct((prev) => {
        return {
          ...prev,
          market_type: e.target.value,
        };
      });
    } else {
      setMarket(e.target.value);
      setProduct((prev) => {
        return {
          ...prev,
          market: e.target.value + market_cat,
        };
      });
    }
  };

  // Render
  return (
    <div className="form-options">
      <select
        required
        name="market_type"
        onChange={handleChange}
        style={{ marginLeft: "0" }}
      >
        <option value="Amazon" selected={product.market_type === "Amazon"}>
          Amazon
        </option>
        <option value="Walmart" selected={product.market_type === "Walmart"}>
          Walmart
        </option>
      </select>
      <select required name="market" onChange={handleChange}>
        <option value="US" selected={market === "US"}>
          United States
        </option>
        <option value="UK" selected={market === "UK"}>
          United Kingdom
        </option>
        <option value="CN" selected={market === "CN"}>
          China
        </option>
        <option value="CA" selected={market === "CA"}>
          Canada
        </option>
        <option value="AU" selected={market === "AU"}>
          Australia
        </option>
        <option value="FR" selected={market === "FR"}>
          France
        </option>
        <option value="DE" selected={market === "DE"}>
          Germany
        </option>
        <option value="IT" selected={market === "IT"}>
          Italy
        </option>
        <option value="JP" selected={market === "JP"}>
          Japan
        </option>
        <option value="KSA" selected={market === "JP"}>
          Saudi Arabia
        </option>
        <option value="MX" selected={market === "MX"}>
          Mexico
        </option>
        <option value="NL" selected={market === "NL"}>
          Netherlands
        </option>
        <option value="ES" selected={market === "ES"}>
          Spain
        </option>
        <option value="UAE" selected={market === "UAE"}>
          United Arab Emirates
        </option>
        <option value="BR" selected={market === "BR"}>
          Brazil
        </option>
        <option value="SG" selected={market === "SG"}>
          Singapore
        </option>
        <option value="TR" selected={market === "TR"}>
          Turkey
        </option>
        <option value="IN" selected={market === "IN"}>
          India
        </option>
      </select>
      <select
        name="market_cat"
        onChange={handleChange}
        style={{ marginRight: "0" }}
      >
        <option value="">Review</option>
        <option value=" - No Review" selected={market_cat === " - No Review"}>
          No Review
        </option>
        <option value=" - Rating" selected={market_cat === " - Rating"}>
          Rating
        </option>
        <option
          value=" - High Commission"
          selected={market_cat === " - High Commission"}
        >
          High Commission
        </option>
        <option value=" - Feedback" selected={market_cat === " - Feedback"}>
          Feedback
        </option>
      </select>
    </div>
  );
}

export default MarketDropdown;
