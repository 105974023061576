// Init
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

// Importing Components
import PicModel from "../components/PicModel.jsx";
import MarketDropdown from "../components/MarketDropdown";

// utils
import { handleInput, handleSubmit } from "../utils/product";
import { copyProduct } from "../utils/copyClipboard";

// View Component
function ViewProduct({ product, role }) {
  return (
    // Details Section
    <ul>
      <li>
        <div>
          <h5>Keyword : </h5>
          <span>{product.keyword}</span>
        </div>
        <div>
          <h5>Sold By : </h5>
          <span>{product.amz_seller}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Brand Name : </h5>
          <span>{product.brand_name}</span>
        </div>
        <div>
          <h5>Product ID : </h5>
          <span>{product.product_no}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Market Type : </h5>
          <span>{product.market_type}</span>
        </div>
        <div>
          <h5>Market : </h5>
          <span>{product.market}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Seller Name : </h5>
          <span>{product.seller_name}</span>
        </div>
        <div>
          <h5>AMZ Link : </h5>
          <span>{product.amz_link}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Commission : </h5>
          <span>{product.commission}</span>
        </div>
        {role !== "user" && (
          <div>
            <h5>Chinese Seller : </h5>
            <span>{product.chinese_seller}</span>
          </div>
        )}
      </li>
      <li>
        <div>
          <h5>Overall Sale Limit : </h5>
          <span>{product.overall_sale_limit}</span>
        </div>
        <div>
          <h5>Daily Sale Limit : </h5>
          <span>{product.sale_limit}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Product Status : </h5>
          <span>{product.active ? "Enabled" : "Disabled"}</span>
        </div>
        <div>
          <h5>Product Category : </h5>
          <span>{product.category?.split("_").join(" ")}</span>
        </div>
      </li>
      {role === "admin" && (
        <li>
          <div>
            <h5>Portal Commission : </h5>
            <span>{product.portal_commission}</span>
          </div>
          <div>
            <h5>Last Disabled Date : </h5>
            <span>{product.disabled_date}</span>
          </div>
        </li>
      )}
      <li>
        <div>
          <h5>Instructions : </h5>
          <span>{product.instructions}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Refund Condition : </h5>
          <span>{product.refund_conditions}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Commission Condition : </h5>
          <span>{product.commission_conditions}</span>
        </div>
      </li>
    </ul>
  );
}

// Edit Component
function EditProduct({ product, setProduct, setEditing, role }) {
  let [loading, setLoading] = useState(false);

  // Render
  return (
    // Form
    <form onSubmit={(e) => handleSubmit(e, product, setEditing, setLoading)}>
      <ul className="view-update-form">
        <h3>Update Product</h3>
        <li className="form-input-container">
          <input
            type="text"
            name="keyword"
            placeholder="Keyword"
            value={product.keyword}
            onChange={(e) => handleInput(e, setProduct)}
            required
          />
          <input
            type="text"
            name="brand_name"
            placeholder="Brand Name"
            value={product.brand_name}
            onChange={(e) => handleInput(e, setProduct)}
            required
          />
        </li>
        <MarketDropdown product={product} setProduct={setProduct} />
        <li className="form-input-container">
          <input
            type="text"
            name="amz_seller"
            placeholder="Amazon Seller"
            value={product.amz_seller}
            onChange={(e) => handleInput(e, setProduct)}
            required
          />
          <input
            type="text"
            name="amz_link"
            placeholder="AMZ Link"
            value={product.amz_link}
            onChange={(e) => handleInput(e, setProduct)}
          />
        </li>
        <li className="form-input-container">
          <input
            type="text"
            name="commission"
            placeholder="Commission"
            value={product.commission}
            onChange={(e) => handleInput(e, setProduct)}
            required
          />
          <input
            type="text"
            name="chinese_seller"
            placeholder="Chinese Seller"
            value={product.chinese_seller}
            onChange={(e) => handleInput(e, setProduct)}
            required
          />
        </li>
        <li className="form-input-container">
          <input
            type="number"
            name="overall_sale_limit"
            placeholder="Overall Sale Limit"
            value={product.overall_sale_limit}
            onChange={(e) => handleInput(e, setProduct)}
            required
          />
          <input
            type="number"
            name="sale_limit"
            placeholder="Daily Sale Limit"
            value={product.sale_limit}
            onChange={(e) => handleInput(e, setProduct)}
            required
          />
        </li>
        <li className="form-input-container">
          {role === "admin" && (
            <input
              type="number"
              name="portal_commission"
              placeholder="Portal Commission"
              value={product.portal_commission}
              onChange={(e) => handleInput(e, setProduct)}
              required
            />
          )}
          <select
            required
            name="category"
            onChange={(e) => handleInput(e, setProduct)}
          >
            <option value="General" selected={product.category === "General"}>
              General
            </option>
            <option
              value="Kindle_&_Books"
              selected={product.category === "Kindle_&_Books"}
            >
              Kindle & Books
            </option>
            <option
              value="Electronics"
              selected={product.category === "Electronics"}
            >
              Electronics
            </option>
            <option
              value="Health_&_Beauty"
              selected={product.category === "Health_&_Beauty"}
            >
              Health & Beauty
            </option>
            <option
              value="Baby_Products"
              selected={product.category === "Baby_Products"}
            >
              Baby Products
            </option>
            <option
              value="Gaming_Devices"
              selected={product.category === "Gaming_Devices"}
            >
              Gaming Devices
            </option>
            <option value="Fashion" selected={product.category === "Fashion"}>
              Fashion (Clothes & shoes)
            </option>
            <option
              value="Electronic_Accessories"
              selected={product.category === "Electronic_Accessories"}
            >
              Electronic Accessories
            </option>
            <option
              value="Automotive_Accessories"
              selected={product.category === "Automotive_Accessories"}
            >
              Automotive Accessories
            </option>
            <option
              value="Expensive_Products"
              selected={product.category === "Expensive_Products"}
            >
              Expensive Products
            </option>
            <option
              value="Pet_Related"
              selected={product.category === "Pet_Related"}
            >
              Pet Related
            </option>
            <option
              value="Home_&_Kitchen"
              selected={product.category === "Home_&_Kitchen"}
            >
              Home & Kitchen
            </option>
          </select>
        </li>

        <textarea
          name="instructions"
          cols="30"
          rows="4"
          placeholder="Instructions"
          value={product.instructions}
          onChange={(e) => handleInput(e, setProduct)}
          required
        ></textarea>
        <textarea
          name="refund_conditions"
          cols="30"
          rows="4"
          placeholder="Refund Conditions"
          value={product.refund_conditions}
          onChange={(e) => handleInput(e, setProduct)}
          required
        ></textarea>
        <textarea
          name="commission_conditions"
          cols="30"
          rows="4"
          placeholder="Commission Conditions"
          value={product.commission_conditions}
          onChange={(e) => handleInput(e, setProduct)}
          required
        ></textarea>

        {/* Update btn */}
        <button className="form-submit-btn" disabled={loading}>
          {loading && <i className="fa fa-refresh fa-spin" />}
          &nbsp; Update Now
        </button>
      </ul>
    </form>
  );
}

// Main Component
function Product({ role }) {
  // Initializing States
  let [product, setProduct] = useState({ market: "" });
  let [editing, setEditing] = useState(false);
  let { productNo } = useParams();
  let [picModel, setPicModel] = useState({ display: false, src: "" });

  // Filtering Product and changing page title
  useEffect(() => {
    // Changing the title of page
    document.title = "Product Detail - PM Pro";

    // Getting the product
    axios
      .get(`/products/${productNo}`)
      .then((product) => {
        if (product.data !== null) {
          setProduct(product.data);
        } else {
          toast.error("Product not found...");
        }
      })
      .catch((err) => {
        // If session expired then redirect lo login page
        if (err.response.status === 401) {
          window.location = "/login";
        }

        console.log({ ...err });
      });
  }, [productNo]);

  // Update P\portal commission based on market type
  useEffect(() => {
    switch (product.market.split(" - ")[1]) {
      case "No Review":
        setProduct((prev) => ({ ...prev, portal_commission: 75 }));
        break;

      case "Rating":
        setProduct((prev) => ({ ...prev, portal_commission: 100 }));
        break;

      case "Feedback":
        setProduct((prev) => ({ ...prev, portal_commission: 100 }));
        break;

      default:
        setProduct((prev) => ({ ...prev, portal_commission: 150 }));
    }
  }, [product.market]);

  // Render
  return (
    <section className="view">
      {/* Image Model */}
      {picModel.display && <PicModel model={picModel} setModel={setPicModel} />}

      {/* Head */}
      <div className="view-heading">
        <span>OVERVIEW</span>
        <h3>Product Details</h3>
      </div>

      {/* Body */}
      <div className="view-body">
        {/* Picture Section */}
        <div className="view-pics">
          {/* Product Pic */}
          <div className="view-pic">
            <h3>Picture</h3>
            {product.pic && (
              <img
                src={product.pic}
                alt="Product Pic"
                onClick={() =>
                  setPicModel({
                    display: true,
                    src: product.pic,
                  })
                }
              />
            )}

            {/* File Input */}
            {role === "user" ? null : (
              <label htmlFor="product-pic" className="file-upload-btn">
                <i className="fas fa-camera"></i>
                &nbsp; Change Pic
                <input
                  type="file"
                  id="product-pic"
                  name="pic"
                  onChange={(e) => handleInput(e, setProduct, product)}
                  hidden
                />
              </label>
            )}
          </div>

          {/* Amazon Pic */}
          <div className="view-amz-pic">
            <h3>Amazon Picture</h3>
            {product.amazon_pic && (
              <img
                src={product.amazon_pic}
                alt="Amazon Screenshot"
                onClick={() =>
                  setPicModel({
                    display: true,
                    src: product.amazon_pic,
                  })
                }
              />
            )}

            {/* File input */}
            {role === "user" ? null : (
              <label htmlFor="amazon-pic" className="file-upload-btn">
                <i className="fas fa-camera"></i>
                &nbsp; Change Pic
                <input
                  type="file"
                  id="amazon-pic"
                  name="amazon_pic"
                  onChange={(e) => handleInput(e, setProduct, product)}
                  hidden
                />
              </label>
            )}
          </div>
        </div>

        {/* View Details Section */}
        <div className="view-details">
          {/* Head */}
          <div className="view-details-head">
            <h4>Details</h4>
            {role === "user" ? null : (
              <button
                className="green-btn"
                onClick={() => setEditing(!editing)}
              >
                <i className="fas fa-pen"></i>&nbsp;
                {editing ? "Cancel" : "Edit"}
              </button>
            )}
            <i
              className="far fa-copy"
              id="copy_icon"
              onClick={() => copyProduct(product)}
            ></i>
          </div>

          {/* Body */}
          {editing ? (
            <EditProduct
              product={product}
              setProduct={setProduct}
              setEditing={setEditing}
              role={role}
            />
          ) : (
            <ViewProduct product={product} role={role} />
          )}
        </div>
      </div>
    </section>
  );
}

// Export
export default Product;
