// Init
import React, { useState, useEffect } from "react";
import axios from "axios";

import MarketDropdown from "../components/MarketDropdown";

// Controller
import {
  handleInput,
  handleBulkUpload,
  handleSubmit,
} from "../utils/addProduct";

// Component
function AddProduct() {
  // Initializing States
  let [product, setProduct] = useState({
    market: "US",
    market_type: "Amazon",
    category: "General",
    portal_commission: 100,
    instructions:
      "1. Review need to be submitted after 7 days of shipment received.\n" +
      "2. Must use keyword for product search.\n" +
      "3. Buyer should be honest, scammer buyer is responsibility of agent.\n" +
      "4. Don't search with Brand Name.\n" +
      "5. A PM should provide buyer’s verification in case a PMM asks for it in case of expensive products.",
    refund_conditions:
      "1. Refund will be processed on 5 star review live on amazon.\n" +
      "2. Product cost + pp fee (Refund time could be 96 to 120 hours).",
    commission_conditions:
      "1. Full Commission will be paid on mature leads.\n" +
      "2. Mature lead will be considered those order which are refunded against 5 star reviews.\n" +
      "3. Not included deleted and feedback.\n" +
      "4. On deleted, commission will be paid as per PM Pro rules.",
  });
  let [loading, setLoading] = useState(false);

  // Image Upload Progress
  let [progress, setProgress] = useState({ amazon_pic: 0, pic: 0 });

  // Changing the title of page
  useEffect(() => {
    document.title = "Add Product - PM Pro";
  }, []);

  // Update P\portal commission based on market type
  useEffect(() => {
    switch (product.market.split(" - ")[1]) {
      case "No Review":
        setProduct((prev) => ({ ...prev, portal_commission: 75 }));
        break;

      case "Rating":
        setProduct((prev) => ({ ...prev, portal_commission: 100 }));
        break;

      case "Feedback":
        setProduct((prev) => ({ ...prev, portal_commission: 100 }));
        break;

      default:
        setProduct((prev) => ({ ...prev, portal_commission: 150 }));
    }
  }, [product.market]);

  // Render
  return (
    // Main
    <section className="form">
      {/* Main Heading */}
      <h2>Product</h2>

      {/* Form */}
      <form
        onSubmit={(e) =>
          handleSubmit(e, product, setProduct, setLoading, setProgress)
        }
      >
        {/* Form heading */}
        <h3>
          Add New Product{" "}
          <a
            href="https://api.pmrpo.com.pk/uploads/template/products.xlsx"
            style={{
              textDecoration: "none",
              color: "inherit",
              margin: "0 10px 0 5px",
            }}
          >
            <i
              title="Download template for bulk upload"
              className="fas fa-arrow-circle-down"
            ></i>
          </a>
          <label htmlFor="excel_file_products" style={{ cursor: "pointer" }}>
            <i
              title="Upload bulk products"
              className="fas fa-arrow-circle-up"
            ></i>

            <input
              type="file"
              id="excel_file_products"
              name="products"
              onChange={handleBulkUpload}
              hidden
            />
          </label>
          {/*&nbsp; &nbsp; (Seller ID is {user.id})*/}
        </h3>
        <div className="form-container two-form-col">
          {/* Col 1 */}
          <section>
            <input
              type="text"
              name="keyword"
              placeholder="Keyword"
              value={product.keyword}
              onChange={(e) => handleInput(e, setProduct)}
              required
            />
            <div className="form-input-container">
              <input
                type="text"
                name="brand_name"
                placeholder="Brand Name"
                value={product.brand_name}
                onChange={(e) => handleInput(e, setProduct)}
                required
              />
              <input
                type="text"
                name="amz_seller"
                placeholder="AMZ Seller"
                value={product.amz_seller}
                onChange={(e) => handleInput(e, setProduct)}
                required
              />
            </div>
            <div className="form-input-container">
              <input
                type="text"
                name="amz_link"
                placeholder="AMZ Link"
                value={product.amz_link}
                onChange={(e) => handleInput(e, setProduct)}
              />
              <input
                type="text"
                name="chinese_seller"
                placeholder="Chinese Seller"
                value={product.chinese_seller}
                onChange={(e) => handleInput(e, setProduct)}
                required
              />
            </div>
            <div>
              <MarketDropdown product={product} setProduct={setProduct} />
            </div>

            {/* Category Input */}
            <div className="form-options">
              <label htmlFor="category">Select Category: </label>
              <select
                required
                id="category"
                name="category"
                onChange={(e) => handleInput(e, setProduct)}
              >
                <option value="General" selected>
                  General
                </option>
                <option value="Kindle_&_Books">Kindle & Books</option>
                <option value="Electronics">Electronics</option>
                <option value="Health_&_Beauty">Health & Beauty</option>
                <option value="Baby_Products">Baby Products</option>
                <option value="Gaming_Devices">Gaming Devices</option>
                <option value="Fashion">Fashion (Clothes & shoes)</option>
                <option value="Electronic_Accessories">
                  Electronic Accessories
                </option>
                <option value="Automotive_Accessories">
                  Automotive Accessories
                </option>
                <option value="Expensive_Products">Expensive Products</option>
                <option value="Pet_Related">Pet Related</option>
                <option value="Home_&_Kitchen">Home & Kitchen</option>
              </select>
            </div>
            {/* End of category input */}

            <div className="form-input-container">
              <div className="input-file-container">
                <label htmlFor="amazonPic">Amazon Picture</label>
                <div>
                  <input
                    type="file"
                    id="amazonPic"
                    name="amazon_pic"
                    onChange={(e) => handleInput(e, setProduct, setProgress)}
                    required
                  />
                  <progress value={progress.amazon_pic} max="100" />
                </div>
              </div>
              <div className="input-file-container">
                <label htmlFor="productPicture">Picture</label>
                <div>
                  <input
                    type="file"
                    name="pic"
                    id="productPic"
                    onChange={(e) => handleInput(e, setProduct, setProgress)}
                    required
                  />
                  <progress value={progress.pic} max="100" />
                </div>
              </div>
            </div>
            <div className="form-input-container">
              <input
                type="text"
                name="commission"
                placeholder="Commission"
                value={product.commission}
                onChange={(e) => handleInput(e, setProduct)}
                required
              />
              <input
                type="text"
                name="portal_commission"
                placeholder="Portal Commission"
                value={`Portal commission: Rs${product.portal_commission}`}
                // onChange={(e) => handleInput(e, setProduct)}
                style={{ color: "rgba(0,0, 0, 0.4)" }}
                disabled
              />
            </div>
            <textarea
              name="instructions"
              id="instructions"
              cols="30"
              rows="5"
              placeholder="Instructions..."
              value={product.instructions}
              onChange={(e) => handleInput(e, setProduct)}
              required
            ></textarea>
          </section>

          {/* Col 2 */}
          <section>
            <textarea
              name="refund_conditions"
              cols="30"
              rows="5"
              placeholder="Refund Conditions"
              value={product.refund_conditions}
              onChange={(e) => handleInput(e, setProduct)}
              required
            ></textarea>
            <textarea
              name="commission_conditions"
              cols="30"
              rows="4"
              placeholder="Commission Conditions"
              value={product.commission_conditions}
              onChange={(e) => handleInput(e, setProduct)}
              required
            ></textarea>
            <div className="form-input-container">
              <input
                type="number"
                name="sale_limit"
                placeholder="Sale limit per day"
                value={product.sale_limit}
                onChange={(e) => handleInput(e, setProduct)}
                required
              />
              <input
                type="number"
                name="overall_sale_limit"
                placeholder="Overall sale limit"
                value={product.overall_sale_limit}
                onChange={(e) => handleInput(e, setProduct)}
                required
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="form-submit-btn"
              disabled={loading}
            >
              {loading && <i className="fa fa-refresh fa-spin" />}
              &nbsp; Add Now
            </button>
          </section>
        </div>
      </form>
    </section>
  );
}

// Export
export default AddProduct;
